table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  width: 100%;
}

table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table th {
  padding: 25px;
  font-size: large;
  /* font-weight: 900; */
  position: sticky;
  top: 0;
}

table tr:nth-child(even) {
  background-color: #f4efde;
}

table tr:nth-child(odd) {
  background-color: #fbf9f3;
}
/* 
table tr:hover {
  background-color: #bead7c;
} */

.orderTable tr:hover{
  background-color:  #cdc8ba;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #bead7c; /* background-color: #bead7c; */
  color: white;
}

.orderTable th{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #bead7c; /* background-color: #bead7c; #485202; */
  color: white;
}

.legendBox {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.newLegendColour {
  background: #e6ffec;
}

.oldLegendColour {
  background: #ffebe9;
}

.table-tooltip span {
  visibility: hidden;
  background-color: #e9eac3;
  color: black;
  font-size: medium;

  border-radius: 6px;
  position: absolute;

  padding: 10px;
}

.table-tooltip:hover span {
  visibility: visible;
}
