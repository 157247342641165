* {
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout a{
   color: black;
}

.layout a:hover{
  color: #433719;
  /* font-size:1.2em; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ::-webkit-scrollbar {
  display: none;
} */

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hoverSecWhiteButton:hover {
  border: 2px;
  border-radius: 10px;
  border-style: solid;
}

.hoverButton:hover {
  border: 2px;
  border-radius: 25px;
  border-style: solid;
} 

.navDropdown a
{
    color: palevioletred !important;
}
